import {
  Component, OnInit, ViewChild, OnDestroy,
  ElementRef, AfterViewInit, ChangeDetectorRef, HostListener
} from "@angular/core";

import { ROUTES } from './vertical-menu-routes.config';
import { HROUTES } from '../horizontal-menu/navigation-routes.config';

import { Router } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { customAnimations } from "../animations/custom-animations";
import { DeviceDetectorService } from 'ngx-device-detector';
import { ConfigService } from '../services/config.service';
import { Subscription } from 'rxjs';
import { LayoutService } from '../services/layout.service';


@Component({
  selector: "app-sidebar",
  templateUrl: "./vertical-menu.component.html",
  animations: customAnimations,
})
export class VerticalMenuComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('toggleIcon') toggleIcon: ElementRef;
  public menuItems: any[];
  level: number = 0;
  logoUrl = 'assets/img/Zatpat-logo.png';
  public config: any = {};
  protected innerWidth: any;
  layoutSub: Subscription;
  configSub: Subscription;
  perfectScrollbarEnable = true;
  collapseSidebar = false;
  resizeTimeout;
  //isAdminLogedIn:boolean = (GlobalComponent.ClientIDf==0);
  isAdminLogedIn:boolean = false;
  pnUserMnu:boolean = true;

  isClient : boolean = (Number(localStorage.getItem("ClientIDF"))>0);
  Username = localStorage.getItem("UserName");

  profilePicture = (localStorage.getItem("ProfilePicture") == "null" || (Number(localStorage.getItem("ClientIDF"))==0)) ? "/assets/img/NoPhofile.png" : "/assets/client-profile/" + localStorage.getItem('ClientIDF') + "/" + localStorage.getItem("ProfilePicture");
  
  constructor(
    private router: Router,
    public translate: TranslateService,
    private layoutService: LayoutService,
    private configService: ConfigService,
    private cdr: ChangeDetectorRef,
    private deviceService: DeviceDetectorService
    ) {
      this.config = this.configService.templateConf;
      this.innerWidth = window.innerWidth;
      this.isTouchDevice();
      
      this.isAdminLogedIn = (Number(localStorage.getItem("ClientIDF"))==0);
  }


  ngOnInit() {
    this.menuItems = ROUTES;
    //this.menuItems[10].title="test";
    //this.cdr.detectChanges();
  }

  ngAfterViewInit() {
    this.configSub = this.configService.templateConf$.subscribe((templateConf) => {
      if (templateConf) {
        this.config = templateConf;
      }
      this.loadLayout();
      this.cdr.markForCheck();

    });

    this.layoutSub = this.layoutService.overlaySidebarToggle$.subscribe(
      collapse => {
        if (this.config.layout.menuPosition === "Side") {
          this.collapseSidebar = collapse;
        }
      });

  }


  @HostListener('window:resize', ['$event'])
  onWindowResize(event) {
      if (this.resizeTimeout) {
          clearTimeout(this.resizeTimeout);
      }
      this.resizeTimeout = setTimeout((() => {
        this.innerWidth = event.target.innerWidth;
          this.loadLayout();
      }).bind(this), 500);
  }

  loadLayout() {

    if (this.config.layout.menuPosition === "Top") { // Horizontal Menu
      if (this.innerWidth < 1200) { // Screen size < 1200
        this.menuItems = HROUTES;
      }
    }
    else if (this.config.layout.menuPosition === "Side") { // Vertical Menu{
      this.menuItems = ROUTES;
    }




    if (this.config.layout.sidebar.backgroundColor === 'white') {
      this.logoUrl = 'assets/img/Zatpat-logo.png';
    }
    else {
      this.logoUrl = 'assets/img/Zatpat-logo.png';
    }

    if(this.config.layout.sidebar.collapsed) {
      this.collapseSidebar = true;
    }
    else {
      this.collapseSidebar = false;
    }
  }

  toggleSidebar() {
    let conf = this.config;
    conf.layout.sidebar.collapsed = !this.config.layout.sidebar.collapsed;
    this.configService.applyTemplateConfigChange({ layout: conf.layout });

    if(this.collapseSidebar){
      let sidebarCollapse = <HTMLInputElement>(document.getElementById('appsidebarToggle'));
      sidebarCollapse.style.width = "60px";
    }

    setTimeout(() => {
      this.fireRefreshEventOnWindow();

      let sidebarCollapse = <HTMLInputElement>(document.getElementById('appsidebarToggle'));
      sidebarCollapse.style.removeProperty("width");
      
    }, 300);
  }

  fireRefreshEventOnWindow = function () {
    const evt = document.createEvent("HTMLEvents");
    evt.initEvent("resize", true, false);
    window.dispatchEvent(evt);
  };

  CloseSidebar() {
    this.layoutService.toggleSidebarSmallScreen(false);
  }

  isTouchDevice() {

    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();

    if (isMobile || isTablet) {
      this.perfectScrollbarEnable = false;
    }
    else {
      this.perfectScrollbarEnable = true;
    }

  }


  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
    if (this.configSub) {
      this.configSub.unsubscribe();
    }

  }

  toggleUserMnu(){
    if((Number(localStorage.getItem("ClientIDF"))>0))
    {
      this.router.navigate(['/summary-package']);
    }
    //this.pnUserMnu = !this.pnUserMnu;
  }

}
