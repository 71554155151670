<!--Footer Starts-->
<footer class="footer">
  <p class="copyright clearfix text-muted m-0">
    <span class="d-none d-sm-inline-block">Whatsapp Support<a id="zatpatmachines"
      href="http://api.whatsapp.com/send?phone=918169980823" target="blank"> 91 8169980823</a></span>
    Copyright &copy; {{currentDate | date: 'yyyy'}} <a id="zatpatmachines"
      href="https://www.zatpatmachines.com/" target="blank">Zatpat Machines</a>
    <span class="d-none d-sm-inline-block">, All rights reserved.</span>
  </p>
</footer>
<!--Footer Ends-->
