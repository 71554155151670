import { RouteInfo } from './vertical-menu.metadata';

//Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [
  {
    path: '/admin/dashboard', title: 'Dashboard', icon: 'ft-home', class: '', badge: '', badgeClass: '', isAdminMenu : true, isExternalLink: false, submenu: []
  },
  {
    path: '/admin/machine-directory', title: 'Machine Spider', icon: 'ft-anchor', class: '', badge: '', badgeClass: '', isAdminMenu : true, isExternalLink: false, submenu: []
  },
  {
    path: '/admin/bulk-machines', title: 'Bulk Machines', icon: 'ft-layout', class: '', badge: '', badgeClass: '', isAdminMenu : true, isExternalLink: false, submenu: []
  },
  { path: '/admin/enquiries', title: 'Enquiries', icon: 'fa fa-question-circle', class: '', badge: '', badgeClass: '', isAdminMenu : true, isExternalLink: false, submenu: [] },
  { path: '/admin/master/supplier', title: 'Supplier', icon: 'ft-truck', class: '', badge: '', badgeClass: '', isAdminMenu : true, isExternalLink: false, submenu: [] },
  {
    path: '/admin/company-directory', title: 'Company Spider', icon: 'ft-box', class: '', badge: '', badgeClass: '', isAdminMenu : true, isExternalLink: false, submenu: []
  },
  {
    path: '/admin/client', title: 'Client', icon: 'ft-grid', class: '', badge: '', badgeClass: '', isAdminMenu : true, isExternalLink: false, submenu: []
  },
  {
    path: '/admin/post-feed', title: 'Post Feed', icon: 'ft-codepen', class: '', badge: '', badgeClass: '', isAdminMenu : true, isExternalLink: false, submenu: []
  },
  {
    path: '', title: 'Import Data', icon: 'ft-layers', class: 'has-sub', badge: '', badgeClass: '', isAdminMenu : true, isExternalLink: false,
    submenu: [
        { path: '/admin/importdata/europages', title: 'Euro Pages', icon: 'ft-arrow-right', class: '', badge: '', badgeClass: '', isAdminMenu : true, isExternalLink: false, submenu: [] },
    ]
  },
  {
      path: '', title: 'Master', icon: 'ft-tag', class: 'has-sub', badge: '', badgeClass: '', isAdminMenu : true, isExternalLink: false,
      submenu: [
        { path: '/admin/master/company-category', title: 'Company Category', icon: 'ft-arrow-right', class: '', badge: '', badgeClass: '', isAdminMenu : true, isExternalLink: false, submenu: [] },
        { path: '/admin/master/company-profile', title: 'Company Profile', icon: 'ft-arrow-right', class: '', badge: '', badgeClass: '', isAdminMenu : true, isExternalLink: false, submenu: [] },
        { path: '/admin/master/machine-category', title: 'Machine Category', icon: 'ft-arrow-right', class: '', badge: '', badgeClass: '', isAdminMenu : true, isExternalLink: false, submenu: [] },
        { path: '/admin/master/machine-type', title: 'Machine Type', icon: 'ft-arrow-right', class: '', badge: '', badgeClass: '', isAdminMenu : true, isExternalLink: false, submenu: [] },
        { path: '/admin/master/brand', title: 'Brand', icon: 'ft-arrow-right', class: '', badge: '', badgeClass: '', isAdminMenu : true, isExternalLink: false, submenu: [] },
        { path: '/admin/master/factory-report-template', title: 'Factory Report Template', icon: 'ft-arrow-right', class: '', badge: '', badgeClass: '', isAdminMenu : true, isExternalLink: false, submenu: [] },
        { path: '/admin/master/supplier-type', title: 'Supplier Category', icon: 'ft-arrow-right', class: '', badge: '', badgeClass: '', isAdminMenu : true, isExternalLink: false, submenu: [] },
        { path: '/admin/master/lead-type', title: 'Lead Type', icon: 'ft-arrow-right', class: '', badge: '', badgeClass: '', isAdminMenu : true, isExternalLink: false, submenu: [] },
        { path: '/admin/master/machine-controller', title: 'Machine Controller', icon: 'ft-arrow-right', class: '', badge: '', badgeClass: '', isAdminMenu : true, isExternalLink: false, submenu: [] },
        // { path: '/admin/master/supplier', title: 'Supplier', icon: 'ft-arrow-right', class: '', badge: '', badgeClass: '', isAdminMenu : true, isExternalLink: false, submenu: [] },
      ]
  },
  {
    path: '', title: 'Reporting', icon: 'ft-bar-chart-line', class: 'has-sub', badge: '', badgeClass: '', isAdminMenu : true, isExternalLink: false,
    submenu: [
        { path: '/admin/reporting/data-summary', title: 'Data Summary', icon: 'ft-arrow-right', class: '', badge: '', badgeClass: '', isAdminMenu : true, isExternalLink: false, submenu: [] },
        { path: '/admin/reporting/invoice-report', title: 'Invoice Report', icon: 'ft-arrow-right', class: '', badge: '', badgeClass: '', isAdminMenu : true, isExternalLink: false, submenu: [] },
    ]
  },
  {
    path: '', title: 'Settings', icon: 'ft-settings', class: 'has-sub', badge: '', badgeClass: '', isAdminMenu : true, isExternalLink: false,
    submenu: [
      { path: '/admin/settings/admin-user', title: 'Admin User', icon: 'ft-arrow-right', class: '', badge: '', badgeClass: '', isAdminMenu : true, isExternalLink: false, submenu: [] },
      { path: '/admin/settings/config', title: 'Config', icon: 'ft-arrow-right', class: '', badge: '', badgeClass: '', isAdminMenu : true, isExternalLink: false, submenu: [] },
      { path: '/admin/settings/email-template', title: 'Email Template', icon: 'ft-arrow-right', class: '', badge: '', badgeClass: '', isAdminMenu : true, isExternalLink: false, submenu: [] },
    ]
  },
  {
    path: '', title: 'Communicate', icon: 'ft-command', class: 'has-sub', badge: '', badgeClass: '', isAdminMenu : true, isExternalLink: false,
    submenu: [
        { path: '/admin/communicate/ticket', title: 'Ticket', icon: 'ft-tag', class: '', badge: '', badgeClass: '', isAdminMenu : true, isExternalLink: false, submenu: [] },
        { path: '/admin/communicate/flash-message', title: 'Flash Message', icon: 'ft-message-square', class: '', badge: '', badgeClass: '', isAdminMenu : true, isExternalLink: false, submenu: [] },
        { path: '/admin/communicate/newsletter', title: 'News Letter', icon: 'ft-layout', class: '', badge: '', badgeClass: '', isAdminMenu : true, isExternalLink: false, submenu: [] },
        { path: '/admin/communicate/subscriber', title: 'Subscriber', icon: 'ft-bell', class: '', badge: '', badgeClass: '', isAdminMenu : true, isExternalLink: false, submenu: [] },
    ]
  },
  {
    path: '/admin/activity-log', title: 'इतिहास दिखाओ', icon: 'ft-activity', class: '', badge: '', badgeClass: '', isAdminMenu : true, isExternalLink: false, submenu: []
  },
  {
    path: '/user/logout', title: 'Logout', icon: 'ft-power', class: '', badge: '', badgeClass: '', isAdminMenu : true, isExternalLink: false, submenu: []
  },
  // {
  //   path: '/page', title: 'TestPage', icon: 'ft-home', class: '', badge: '', badgeClass: '', isAdminMenu : true, isExternalLink: false, submenu: []
  // },
  // {
  //   path: '/dashboard', title: 'Dashboard', icon: 'ft-home', class: '', badge: '', badgeClass: '', isAdminMenu : false, isExternalLink: false, submenu: []
  // },
  // {
  //   path: '/crm/company', title: 'Company Spider', icon: 'ft-box', class: '', badge: '', badgeClass: '', isAdminMenu : false, isExternalLink: false, submenu: []
  // },
  {
    path: '/crm/machine', title: 'Machine Spider', icon: 'ft-anchor', class: '', badge: '', badgeClass: '', isAdminMenu : false, isExternalLink: false, submenu: []
  },
  {
    path: '/feeds', title: 'Feed Spider', icon: 'ft-layers', class: '', badge: '', badgeClass: '', isAdminMenu : false, isExternalLink: false, submenu: []
  },
  {
    path: '/company', title: 'Company', icon: 'ft-box', class: '', badge: '', badgeClass: '', isAdminMenu : false, isExternalLink: false, submenu: []
  },
  {
    path: '/contact', title: 'Contact', icon: 'ft-phone', class: '', badge: '', badgeClass: '', isAdminMenu : false, isExternalLink: false, submenu: []
  },
  {
    path: '', title: 'WhatsApp', icon: 'fa fa-whatsapp', class: 'has-sub', badge: '', badgeClass: '', isAdminMenu : false, isExternalLink: false,
    submenu: [
        { path: '/whatsapp', title: 'Bulk WhatsApp', icon: 'fa fa-whatsapp', class: '', badge: '', badgeClass: '', isAdminMenu : false, isExternalLink: false, submenu: [] },
        { path: '/WhatsappTemplateMessage', title: 'Message Template', icon: 'ft-message-square', class: '', badge: '', badgeClass: '', isAdminMenu : false, isExternalLink: false, submenu: [] },
    ]
  },
  {
    path: '/my-factory', title: 'My Factory', icon: 'ft-codepen', class: '', badge: '', badgeClass: '', isAdminMenu : false, isExternalLink: false, submenu: []
  },
  {
    path: '/help', title: 'Help', icon: 'ft-help-circle', class: '', badge: '', badgeClass: '', isAdminMenu : false, isExternalLink: false, submenu: []
  },
  {
    path: '/user/logout', title: 'Logout', icon: 'ft-power', class: '', badge: '', badgeClass: '', isAdminMenu : false, isExternalLink: false, submenu: []
  },
  // {
  //   path: '/page', title: 'TestPage', icon: 'ft-home', class: '', badge: '', badgeClass: '', isAdminMenu : false, isExternalLink: false, submenu: []
  // },
  // {
  //   path: '/post-feed', title: 'Post Feed', icon: 'ft-arrow-right', class: '', badge: '', badgeClass: '', isAdminMenu : false, isExternalLink: false, submenu: []
  // },
  // {
  //   path: '/profile-view', title: 'View Profile', icon: 'ft-home', class: '', badge: '', badgeClass: '', isAdminMenu : false, isExternalLink: false, submenu: []
  // },
  // {
  //   path: '', title: 'Menu Levels', icon: 'ft-align-left', class: 'has-sub', badge: '3', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isAdminMenu : true, isExternalLink: false,
  //   submenu: [
  //       { path: '/YOUR-ROUTE-PATH', title: 'Second Level', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isAdminMenu : true, isExternalLink: false, submenu: [] },
  //       {
  //           path: '', title: 'Second Level Child', icon: 'ft-arrow-right submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isAdminMenu : true, isExternalLink: false,
  //           submenu: [
  //               { path: '/YOUR-ROUTE-PATH', title: 'Third Level 1.1', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isAdminMenu : true, isExternalLink: false, submenu: [] },
  //               { path: '/YOUR-ROUTE-PATH', title: 'Third Level 1.2', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isAdminMenu : true, isExternalLink: false, submenu: [] },
  //           ]
  //       },
  //   ]
  // },
];
