import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import {CommonModule} from "@angular/common";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FilterPipe } from './filter.pipe';
import { SearchPipe } from './search.pipe';
import { ShortNamePipe } from './short-name.pipe';

@NgModule({
  declarations:[FilterPipe, SearchPipe, ShortNamePipe],
  imports:[CommonModule, NgbModule],
  providers: [],
  exports:[FilterPipe, SearchPipe, ShortNamePipe],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})

export class PipeModule{}
