<!-- Sidebar Header starts -->
<div class="sidebar-header" *ngIf="config?.layout.menuPosition === 'Side'" style="background:  #3b7ddd;">
  <div class="logo clearfix">
    <a [routerLink]="['/dashboard']" class="logo-text float-left">
      <div class="logo-img">
        <img [src]="logoUrl" alt="Zatpat Machines"/>
      </div>
      <span class="text align-middle">Zatpat Machines</span>
      <!-- <br>
      <div class="user d-md-flex d-none mr-2">
        <div class="logo-img">
          <img class="avatar" src="assets/img/portrait/small/avatar-s-1.png" alt="avatar" height="35" width="35" />
        </div>
        <span class="text align-middle">{{Username}}</span>
        <span class="text-right text-muted font-small-3">Available</span>
      </div> -->
    </a>
    <a class="nav-toggle d-none d-lg-none d-xl-block" id="sidebarToggle" href="javascript:;"
      (click)="toggleSidebar()">
      <i #toggleIcon [ngClass]="{
        'ft-toggle-left': config.layout.sidebar.collapsed,
        'ft-toggle-right': !config.layout.sidebar.collapsed
      }" class="toggle-icon"></i>
    </a>
    <a class="nav-close d-block d-lg-block d-xl-none" id="sidebarClose" href="javascript:;" (click)="CloseSidebar()">
      <i class="ft-x"></i>
    </a>
  </div>
</div>
<!-- Sidebar Header Ends -->
<!-- Sidebar Content starts -->
<div class="sidebar-content main-menu-content" [perfectScrollbar]  [disabled]="!perfectScrollbarEnable">
  <ul class="navigation">
  <li class="dropdown nav-item mr-1" placement="bottom-left" display="static" ngbDropdown >
    <a class="nav-link user-dropdown d-flex align-items-end" id="dropdownBasic2"
      href="javascript:;" (click)="toggleUserMnu()">
      <div class="logo-img">
        <!-- <img class="avatar" src="assets/img/portrait/small/avatar-s-1.png" alt="avatar" height="35" width="35" /> -->
        <img class="avatar" [src]="profilePicture" alt="avatar" height="35" width="35" />
      </div>
      <div class="user d-md-flex d-none mr-2">
        <span class="text-right">{{Username}}</span>
      </div>
    </a>
    <!-- <div class="userMnu" *ngIf="isClient && pnUserMnu">
      <a routerLink="/profile-view">
          <i class="ft-edit" title="View Profile"></i>
      </a>
      <a routerLink="/user">
          <i class="ft-user" title="Manage User"></i>
      </a>
      <a routerLink="/user/forgot-password">
          <i class="fa fa-key" title="Change Password"></i>
      </a>
      <a routerLink="/user/login">
        <i class="ft-power" title="Logout"></i>
      </a>
      <div class="clearfix"></div>
    </div> -->
  </li>
  </ul>
  <div class="dropdown-divider"></div>
  <div class="nav-container">
    <ul class="navigation" appSidebarDropdown>
      <!-- First level menu -->
      <li appSidebarlink *ngFor="let menuItem of menuItems" [parent]="menuItem.title" [path]="menuItem.path" level="{{level + 1}}" [hasSub]="menuItem.class.includes('has-sub') ? true: false"
        [ngClass]="{'has-sub': menuItem.class.includes('has-sub') ? true: false}"
        [routerLinkActive]="menuItem.submenu.length != 0 ? 'open' : 'active'" [routerLinkActiveOptions]="{exact: false}">
        <a appSidebarAnchorToggle *ngIf="menuItem.submenu.length > 0 && !menuItem.isExternalLink && ((menuItem.isAdminMenu && isAdminLogedIn) || (!menuItem.isAdminMenu && !isAdminLogedIn))">
          <i [ngClass]="[menuItem.icon]"></i>
          <span class="menu-title">{{menuItem.title | translate }}</span>
          <span *ngIf="menuItem.badge && menuItem.badge != ''" [ngClass]="[menuItem.badgeClass]">{{menuItem.badge}}</span>
        </a>
        <a appSidebarAnchorToggle routerLink="{{menuItem.path}}"
          *ngIf="menuItem.submenu.length === 0 && !menuItem.isExternalLink && ((menuItem.isAdminMenu && isAdminLogedIn) || (!menuItem.isAdminMenu && !isAdminLogedIn))">
          <i [ngClass]="[menuItem.icon]"></i>
          <span class="menu-title">{{menuItem.title | translate }}</span>
          <span *ngIf="menuItem.badge && menuItem.badge != '' " [ngClass]="[menuItem.badgeClass]">{{menuItem.badge}}</span>
        </a>
        <a [href]="[menuItem.path]" target="_blank" *ngIf="menuItem.isExternalLink && ((menuItem.isAdminMenu && isAdminLogedIn) || (!menuItem.isAdminMenu && !isAdminLogedIn))">
          <i [ngClass]="[menuItem.icon]"></i>
          <span class="menu-title">{{menuItem.title | translate }}</span>
          <span *ngIf="menuItem.badge && menuItem.badge != '' " [ngClass]="[menuItem.badgeClass]">{{menuItem.badge}}</span>
        </a>
         <!-- Second level menu -->
        <ul appSidebarDropdown class="menu-content" *ngIf="menuItem.submenu.length > 0 ">
          <li appSidebarlink *ngFor="let menuSubItem of menuItem.submenu" [parent]="menuItem.title" [hasSub]="menuSubItem.class.includes('has-sub') ? true: false"
          [path]="menuSubItem.path" level="{{level + 2}}" [ngClass]="{'has-sub': menuSubItem.class.includes('has-sub') ? true: false}"
            [routerLinkActive]="menuSubItem.submenu.length != 0 ? 'open' : 'active'">
            <a appSidebarAnchorToggle *ngIf="menuSubItem.submenu.length > 0 && !menuSubItem.isExternalLink  && ((menuSubItem.isAdminMenu && isAdminLogedIn) || (!menuSubItem.isAdminMenu && !isAdminLogedIn))">
              <i [ngClass]="[menuSubItem.icon]"></i>
              <span *ngIf="menuSubItem.badge && menuSubItem.badge != '' " [ngClass]="[menuSubItem.badgeClass]">{{menuSubItem.badge}}</span>
            </a>
            <a appSidebarAnchorToggle routerLink="{{menuSubItem.path}}"
              *ngIf="menuSubItem.submenu.length === 0 && !menuSubItem.isExternalLink && ((menuSubItem.isAdminMenu && isAdminLogedIn) || (!menuSubItem.isAdminMenu && !isAdminLogedIn))">
              <i [ngClass]="[menuSubItem.icon]"></i>
              <span class="menu-title">{{menuSubItem.title | translate }}</span>
              <span *ngIf="menuSubItem.badge && menuSubItem.badge != '' " [ngClass]="[menuSubItem.badgeClass]">{{menuSubItem.badge}}</span>
            </a>
            <a routerLink="{{menuSubItem.path}}" target="_blank" *ngIf="menuSubItem.isExternalLink && ((menuSubItem.isAdminMenu && isAdminLogedIn) || (!menuSubItem.isAdminMenu && !isAdminLogedIn))">
              <i [ngClass]="[menuSubItem.icon]"></i>
              <span class="menu-title">{{menuSubItem.title | translate }}</span>
              <span *ngIf="menuSubItem.badge && menuSubItem.badge != '' " [ngClass]="[menuSubItem.badgeClass]">{{menuSubItem.badge}}</span>
            </a>
            <!-- Third level menu -->
            <ul appSidebarDropdown class="menu-content" *ngIf="menuSubItem.submenu.length > 0">
              <li appSidebarlink *ngFor="let menuSubsubItem of menuSubItem.submenu" [parent]="menuItem.title" [hasSub]="false"
              [path]="menuSubsubItem.path" level="{{level + 3}}" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                [ngClass]="config.layout.menuPosition === 'Side' ? menuSubsubItem.class : ''">
                <a appSidebarAnchorToggle routerLink="{{menuSubsubItem.path}}"
                  *ngIf="!menuSubsubItem.isExternalLink; else externalSubSubLinkBlock">
                  <i [ngClass]="[menuSubsubItem.icon]"></i>
                  <span class="menu-title">{{menuSubsubItem.title | translate }}</span>
                  <span *ngIf="menuSubsubItem.badge && menuSubsubItem.badge != '' "
                    [ngClass]="[menuSubsubItem.badgeClass]">{{menuSubsubItem.badge}}</span>
                </a>
                <ng-template #externalSubSubLinkBlock>
                  <a routerLink="{{menuSubsubItem.path}}" target="_blank">
                    <i [ngClass]="[menuSubsubItem.icon]"></i>
                    <span class="menu-title">{{menuSubsubItem.title | translate }}</span>
                    <span *ngIf="menuSubsubItem.badge && menuSubsubItem.badge != '' "
                      [ngClass]="[menuSubsubItem.badgeClass]">{{menuSubsubItem.badge}}</span>
                  </a>
                </ng-template>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
    <ul class="navigation" appSidebarDropdown>
      <!-- First level menu -->
      <li appSidebarlink>
        <a href="http://myfactory.zatpatmachines.com/#/user/login" target="_blank" *ngIf="isAdminLogedIn == true">
          <i class="ft-external-link"></i>
          <span class="menu-title">My Factory</span>
        </a>
      </li>
    </ul>
  </div>
</div>
<!-- Sidebar Content Ends -->
