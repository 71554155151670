import { Routes, RouterModule } from '@angular/router';

//Route for content layout with sidebar, navbar and footer.

export const Full_ROUTES: Routes = [
  {
    path: 'page',
    loadChildren: () => import('../../page/page.module').then(m => m.PageModule)
  },
  {
    path: 'admin',
    loadChildren: () => import('../../admin/admin.module').then(m => m.AdminModule)
  },
  {
    path: 'admin/master',
    loadChildren: () => import('../../admin/master/master.module').then(m => m.MasterModule)
  },
  {
    path: 'admin/settings',
    loadChildren: () => import('../../admin/settings/settings.module').then(m => m.SettingsModule)
  },
  {
    path: 'admin/importdata',
    loadChildren: () => import('../../admin/importdata/importdata.module').then(m => m.ImportdataModule)
  },
  {
    path: '',
    loadChildren: () => import('../../client/client.module').then(m => m.ClientModule)
  },
  {
    path: 'crm',
    loadChildren: () => import('../../crm/crm.module').then(m => m.CrmModule)
  },
  {
    path: 'admin/reporting',
    loadChildren: () => import('../../admin/reporting/reporting.module').then(m => m.ReportingModule)
  },
  {
    path: 'admin/communicate',
    loadChildren: () => import('../../admin/communicate/communicate.module').then(m => m.CommunicateModule)
  },
];
